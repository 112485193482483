import React from "react";

function ShortLeftArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={"short-left-arrow " + props.className}
    >
      <path
        d="M2.9996 19.0001H20.9996C21.1818 18.9995 21.3605 18.9493 21.5163 18.8547C21.6721 18.7602 21.7991 18.6249 21.8838 18.4636C21.9684 18.3022 22.0075 18.1207 21.9967 17.9388C21.9859 17.7569 21.9257 17.5814 21.8226 17.4311L12.8226 4.43111C12.4496 3.89211 11.5516 3.89211 11.1776 4.43111L2.1776 17.4311C2.07342 17.581 2.01233 17.7567 2.00096 17.9389C1.98959 18.1211 2.02838 18.303 2.11311 18.4647C2.19784 18.6264 2.32527 18.7619 2.48156 18.8562C2.63785 18.9506 2.81702 19.0004 2.9996 19.0001ZM11.9996 6.75711L19.0916 17.0001H4.9076L11.9996 6.75711Z"
        fill="black"
      />
    </svg>
  );
}

export default ShortLeftArrow;
