import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

// Components
import TopBackground from "../../standalone/TopBackground/TopBackground";
import Input from "./Input";
import TextArea from "./TextArea";
import Warning from "../../standalone/svg/Warning";
import Mail from "../../standalone/svg/Mail";
import Error from "../../standalone/svg/Error";
import Email from "../../standalone/svg/Email";
import Phone from "../../standalone/svg/Phone";
import Location from "../../standalone/svg/Location";
import { useDevice } from "../../../context/Device.context";

// Schema
const ContactSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(1, "Please enter your first name.")
    .max(100, "Can't be over 100 characters.")
    .required("Please enter your first name."),
  lastName: Yup.string()
    .trim()
    .min(1, "Please enter your last name.")
    .max(100, "Can't be over 100 characters.")
    .required("Please enter your last name."),
  parent_email: Yup.string()
    .trim()
    .email("Please enter a valid email.")
    .max(500, "Can't be over 500 characters.")
    .required("Please enter your email."),
  parent_phone: Yup.string()
    .trim()
    .min(10, "Please enter your phone number.")
    .max(30, "Can't be over 30 characters.")
    .matches(
      "^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$",
      "Please enter a valid phone number."
    )
    .required("Please enter your phone number."),
  comments: Yup.string()
    .trim()
    .min(1, "Please enter your message.")
    .max(1000, "Can't be over 1,000 characters.")
    .required("Please enter your message."),
});

function Contact() {
  const {
    REACT_APP_EMAILJS_SERVICE_ID,
    REACT_APP_PARENT_TO_ELAINE_TEMPLATE,
    REACT_APP_EMAILJS_PUBLIC_KEY,
  } = process.env;
  const { isPhone } = useDevice();

  // Page Statuses
  const [successEmail, setSuccessEmail] = useState(false);
  const [emailJSError, setEmailJSError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Formik
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      parent_email: "",
      parent_phone: "",
      comments: "",
    },
    onSubmit: (values, { resetForm, setSubmitting, setErrors }) => {
      const { firstName, lastName, parent_email, parent_phone, comments } =
        values;

      // Sending Email
      setLoading(true);
      emailjs
        .send(
          REACT_APP_EMAILJS_SERVICE_ID,
          REACT_APP_PARENT_TO_ELAINE_TEMPLATE,
          {
            from_name: `${firstName} ${lastName}`,
            parent_email,
            parent_phone,
            message: comments,
          },
          REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setLoading(false);
            setSubmitting(false);
            resetForm();
            setSuccessEmail(true);
          },
          (error) => {
            setLoading(false);
            setSubmitting(false);
            setEmailJSError(true);

            console.log(error.text);
          }
        );
    },
    validationSchema: ContactSchema,
  });

  return (
    <div className="contact-page">
      {/* Loading */}
      {isLoading && (
        <div className="loading center">
          <span className="loader"></span>
        </div>
      )}

      {/* Error */}
      {emailJSError && (
        <div className="contact-page__error-container center">
          <div className="contact-page__error-box">
            {/* Error Header */}
            <div className="contact-page__error-header center">
              <Warning />
            </div>
            {/* Error Message */}
            <div className="contact-page__error-content center-vertical">
              <h3 className="contact-page__error-title">Warning!</h3>
              <p className="contact-page__error-msg">
                Sorry, an error has occured while trying to send your message.
                Please try again.
              </p>
              <button
                type="button"
                className="contact-page__error-close"
                onClick={() => setEmailJSError(false)}
                aria-label="Close Button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success */}
      {successEmail && (
        <div className="contact-page__success-container center">
          <div className="contact-page__success-box">
            {/* Success Header */}
            <div className="contact-page__success-header center">
              <Mail />
            </div>
            {/* Error Message */}
            <div className="contact-page__success-content center-vertical">
              <h3 className="contact-page__success-title">Success!</h3>
              <p className="contact-page__success-msg">
                Your message was successfully sent. We'll try to get back to you
                as soon as possible.
              </p>
              <button
                type="button"
                className="contact-page__success-close"
                onClick={() => setSuccessEmail(false)}
                aria-label="Ok Button"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <TopBackground title="Contact Us" />

      <main className="contact-page__content">
        <h1 className="contact-page__title">
          Have any questions? Ask us here!
        </h1>

        <form className="contact-form" onSubmit={formik.handleSubmit}>
          {/* First Name */}
          <div className="row">
            <div className="contact-form__left">
              <Input
                formik={formik}
                name="firstName"
                type="text"
                label="First Name"
                placeholder="Ex: Elaine"
              />
              <Input
                formik={formik}
                name="lastName"
                type="text"
                label="Last Name"
                placeholder="Ex: Giang"
              />
              <Input
                formik={formik}
                name="parent_email"
                type="email"
                label="Email"
                placeholder="Ex: Example@gmail.com"
              />
              <Input
                formik={formik}
                name="parent_phone"
                type="text"
                label="Phone Number"
                placeholder="Ex: (###) ###-####"
              />
              {!isPhone && (
                <button
                  type="submit"
                  className="contact-form__submit"
                  aria-label="Send Button"
                >
                  Send
                </button>
              )}
            </div>
            <div className="contact-form__right">
              <TextArea
                formik={formik}
                name="comments"
                label="Comments"
                placeholder="Type your message here..."
              />
              <div className="textarea__subtext between-row">
                {formik.errors.comments && formik.touched.comments ? (
                  <p className="contact-form__error-msg contact-form__error-textarea">
                    <Error />
                    {formik.errors.comments}
                  </p>
                ) : null}
                <p className="contact-form__ch-count">
                  Characters Left: {formik.values.comments.length}/1000
                </p>
              </div>
            </div>
            {isPhone && (
              <button
                type="submit"
                className="contact-form__submit"
                aria-label="Send Button"
              >
                Send
              </button>
            )}
          </div>
        </form>

        {/* Schedule a Visit */}
        <div className="contact-sv row">
          <div>
            <h3 className="contact-sv__title">Want to Schedule a Visit?</h3>
            <p className="contact-sv__text">
              To schedule a visit, just send us a message with the time and day
              you would like to visit the daycare. We'll get back to you as soon
              as possible with the availability and confirm your visit.
            </p>
          </div>

          <div className="contact-details">
            <h4 className="contact-details__title">Contact Details</h4>
            <p className="contact-details__info row">
              <Email />
              Elainegiang16@gmail.com
            </p>
            <p className="contact-details__info row">
              <Phone />
              (415) 786-4669
            </p>
            <p className="contact-details__info row">
              <Location />
              U.S.A, CA, San Francisco
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Contact;
