import React from "react";
import { NavLink } from "react-router-dom";

// Components
import CDNImage from "../CDNImage/CDNImage";

function Footer() {
  const links = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "About Us",
      path: "/about",
    },
    {
      label: "Our Program",
      path: "/program",
    },
    {
      label: "Contact Us",
      path: "/contact",
    },
  ];

  return (
    <div className="footer between-row">
      {/* Left Side */}
      <div className="footer-left between-row">
        {/* Logo */}
        <div className="logo row">
          <CDNImage
            imageName="icons/egf-logo"
            className="logo__img"
            alt="Elaine's Daycare"
          />
          <p className="logo__title">Elaine's Daycare</p>
        </div>

        {/* Links */}
        <div className="footer-left__links between-row">
          {links.map((link) => {
            const { label, path } = link;

            return (
              <NavLink key={path} to={path} className="footer-left__link">
                {label}
              </NavLink>
            );
          })}
        </div>
      </div>

      {/* Right Side */}
      <div className="footer-right between-row">
        <div className="footer-contact">
          <h1 className="footer-contact__title">Contact Us</h1>
          <p className="footer-contact__info">
            <strong>Email:</strong> Elainegiang16@gmail.com
          </p>
          <p className="footer-contact__info">
            <strong>Phone:</strong> (415) 786-4669
          </p>
          <p className="footer-contact__info">
            <strong>Location:</strong> U.S.A, CA, San Francisco
          </p>
        </div>

        <p className="footer-right__copyright">
          Copyright © {new Date().getFullYear()} Elaine Giang Family Daycare
        </p>
      </div>
    </div>
  );
}

export default Footer;
