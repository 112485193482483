import React from "react";

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M4.79148 5H10.404L12.2127 9.5225L9.30648 11.46C9.13533 11.5742 8.99502 11.7289 8.89798 11.9103C8.80095 12.0917 8.7502 12.2943 8.75023 12.5C8.75398 12.6175 8.75023 12.5013 8.75023 12.5013V12.5275C8.75097 12.5834 8.75347 12.6393 8.75773 12.695C8.76523 12.7975 8.77773 12.9363 8.80148 13.1075C8.85023 13.445 8.94398 13.91 9.12648 14.4575C9.49398 15.5575 10.214 16.9812 11.6165 18.3837C13.019 19.7862 14.4427 20.5062 15.5415 20.8737C16.0902 21.0562 16.554 21.1487 16.894 21.1987C17.0859 21.2255 17.2791 21.2422 17.4727 21.2488L17.489 21.25H17.499C17.499 21.25 17.639 21.2425 17.5002 21.25C17.7323 21.2499 17.9598 21.1851 18.1571 21.0631C18.3545 20.941 18.514 20.7664 18.6177 20.5588L19.4552 18.8838L25.0002 19.8088V25.2087C22.3615 25.59 15.234 25.9662 9.63398 20.3662C4.03398 14.7662 4.40898 7.6375 4.79148 5ZM11.3415 13.1075L13.6002 11.6025C14.0774 11.2842 14.4306 10.8115 14.6006 10.2637C14.7707 9.71589 14.7471 9.12627 14.534 8.59375L12.7252 4.07125C12.5396 3.60738 12.2193 3.20977 11.8055 2.9297C11.3918 2.64964 10.9036 2.49997 10.404 2.5H4.72648C3.59023 2.5 2.52148 3.28875 2.33523 4.52125C1.91023 7.32375 1.33398 15.6013 7.86648 22.1338C14.399 28.6663 22.6765 28.0887 25.479 27.665C26.7115 27.4775 27.5002 26.41 27.5002 25.2737V19.8088C27.5003 19.217 27.2904 18.6443 26.908 18.1927C26.5255 17.7411 25.9952 17.4399 25.4115 17.3425L19.8665 16.4188C19.3391 16.3307 18.7974 16.4141 18.3209 16.6566C17.8444 16.8991 17.4582 17.2881 17.219 17.7663L16.7865 18.6325C16.6335 18.5948 16.4822 18.5511 16.3327 18.5012C15.5577 18.2437 14.4815 17.7138 13.384 16.6163C12.2865 15.5188 11.7565 14.4425 11.499 13.6662C11.4375 13.4828 11.3853 13.2963 11.3427 13.1075H11.3415Z"
        fill="#00CDCD"
      />
    </svg>
  );
}

export default Phone;
