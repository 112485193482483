import React from "react";
import { NavLink } from "react-router-dom";

// Components
import CDNImage from "../CDNImage/CDNImage";

function Navbar() {
  const links = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "About Us",
      path: "/about",
    },
    {
      label: "Our Program",
      path: "/program",
    },
    {
      label: "Contact Us",
      path: "/contact",
    },
  ];
  const spotsAvailable = false;
  const openingsAvailable = "TWO";

  return (
    <div className="navbar between-row">
      {/* Main */}
      <div className="row">
        <div className="logo row">
          <CDNImage
            imageName="icons/egf-logo"
            className="logo__img"
            alt="Elaine's Daycare"
          />
          <NavLink to="/" className="logo__title">
            Elaine's Daycare
          </NavLink>
        </div>
        <div className="links row">
          {links.map((link) => {
            const { label, path } = link;

            return (
              <NavLink key={path} to={path} className="links__link">
                {label}
              </NavLink>
            );
          })}
        </div>
      </div>

      {/* Buttons Links */}
      <div className="row">
        <NavLink to="/contact" className="contact-link">
          Contact Us
        </NavLink>

        <NavLink to="/signin" className="signup-link">
          Sign In
        </NavLink>
      </div>

      {/* Availability */}
      {spotsAvailable ? (
        <div className="availability">
          We currently have <strong>{openingsAvailable}</strong> spots
          available!
        </div>
      ) : (
        <div className="availability">
          We currently have <strong>zero</strong> spots available.
        </div>
      )}
    </div>
  );
}

export default Navbar;
