import React from "react";

function SHCardText(props) {
  const { time, activity } = props;

  return (
    <div className="sh-cards__card-text">
      <p className="sh-cards__card-time">{time}</p>
      <p className="sh-cards__card-activity">{activity}</p>
    </div>
  );
}

export default SHCardText;
