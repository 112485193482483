import React from "react";
import { AdvancedImage } from "@cloudinary/react";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { autoBest } from "@cloudinary/url-gen/qualifiers/quality";

// Contexts
import { useCDN } from "../../../context/CDN.context";

function CDNImage(props) {
  const { imageName, className, alt } = props;
  const { cld } = useCDN();
  const image = cld.image(imageName).delivery(quality(autoBest()));

  return <AdvancedImage cldImg={image} className={className} alt={alt} />;
}

export default CDNImage;
