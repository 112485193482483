import React, { useEffect } from "react";

// React Router
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// Contexts
import { useDevice } from "./context/Device.context";

// Components
import Home from "./components/pages/Home/Home";
import Navbar from "./components/standalone/Navbar/Navbar";
import Footer from "./components/standalone/Footer/Footer";
import About from "./components/pages/About/About";
import NotFound from "./components/pages/NotFound/NotFound";
import Contact from "./components/pages/Contact/Contact";
import Program from "./components/pages/Program/Program";
import UnderConstruction from "./components/pages/UnderConstruction/UnderConstruction";
import MobileNavbar from "./components/standalone/Navbar/MobileNavbar";
import ScrollButtons from "./components/standalone/buttons/ScrollButtons";

function App() {
  const { isPhone } = useDevice();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <div className="App">
      {/* Navbar */}
      {isPhone ? <MobileNavbar /> : <Navbar />}

      {/* Routes */}
      <Routes>
        {/* For Mobile <Navigate to="/auth" replace / */}
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/program" exact element={<Program />} />
        <Route path="/contact" exact element={<Contact />} />

        {/* Auth */}
        <Route path="/signin" exact element={<UnderConstruction />} />
        <Route path="/signup" exact element={<UnderConstruction />} />
        {/* Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* Footer */}
      <Footer />

      {/* Scroll Btns */}
      <ScrollButtons />
    </div>
  );
}

export default App;
