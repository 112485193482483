import React, { useState } from "react";

// Components
import ShortLeftArrow from "../../standalone/svg/ShortLeftArrow";
import Close from "../../standalone/svg/Close";
import ShortUpArrow from "../../standalone/svg/ShortUpArrow";
import ShortDownArrow from "../../standalone/svg/ShortDownArrow";

function ScrollButtons() {
  const [open, setOpen] = useState(false);

  return (
    <div className={`nav-btn-box center ${open ? "nav-btn-box__active" : ""}`}>
      <button
        type="button"
        className="nav-btn-box__toggle center"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        aria-label="Open / Close Button"
      >
        {open ? <Close /> : <ShortLeftArrow />}
      </button>

      {open && (
        <>
          <button
            type="button"
            className="nav-btns center"
            aria-label="To Top Button"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <ShortUpArrow />
          </button>
          <button
            type="button"
            className="nav-btns center"
            aria-label="To Bottom Button"
            onClick={() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <ShortDownArrow />
          </button>
        </>
      )}
    </div>
  );
}

export default ScrollButtons;
