import React from "react";

function ShortUpArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      className="short-up-arrow"
    >
      <path d="M7.5 0L15 8H0L7.5 0Z" fill="black" />
    </svg>
  );
}

export default ShortUpArrow;
