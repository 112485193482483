import React from "react";

// Components
import TopBackground from "../../standalone/TopBackground/TopBackground";
import SHCardText from "./SHCardText";
import CDNImage from "../../standalone/CDNImage/CDNImage";

function Program() {
  const firstCard = [
    {
      time: "7:00 to 8:30 AM",
      activity: "Arrival/Breakfast",
    },
    {
      time: "8:30 to 9:00 AM",
      activity: "Free Play",
    },
    {
      time: "9:00 to 9:30 AM",
      activity: "Circle Time (Read Aloud & Learning Activities)",
    },
    {
      time: "9:30 to 10:00 AM",
      activity: "Table Activities (Arts & Crafts, Numbers, ABCs, etc.)",
    },
    {
      time: "10:00 to 11:30 AM",
      activity: "Free Play",
    },
    {
      time: "11:30 AM to 12:30 PM",
      activity: "Lunch",
    },
  ];

  const secondCard = [
    {
      time: "12:30 to 1:00 PM",
      activity: "Free Play (Calming Before Nap)",
    },
    {
      time: "1:00 to 3:00 PM",
      activity: "Nap/Quiet Time",
    },
    {
      time: "3:00 to 3:30 PM",
      activity: "Snack Time",
    },
    {
      time: "3:30 to 4:30 PM",
      activity: "Story Time",
    },
    {
      time: "4:30 to 7:00 PM",
      activity: "Free Play until Pick Up",
    },
  ];

  const holidays = [
    "2 weeks in the Summer",
    "New Year's",
    "Lunar New Year",
    "Christmas",
    "Thanksgiving",
    "Memorial Day",
    "President's Day",
    "Independence Day",
    "Labor Day",
    "Martin Luther King Day",
  ];

  return (
    <div className="program-page">
      <TopBackground title="Our Program" />

      <main>
        {/* Description */}
        <div className="program-desc between-row">
          <div className="program-desc__intro">
            <h2 className="program-desc__title">Elaine Giang Family Daycare</h2>
            <p className="program-desc__text">
              Our home-based daycare provides activities for infants from three
              months old to preschool age. The daily schedule includes circle
              time, free play, art, letter activities, and story time. Fun
              learning activities teach problem-solving and are mainly specific
              to trial-and-error experiences. Children are encouraged to solve
              problems independently before intervention to push their growing
              minds.
            </p>
          </div>

          <CDNImage
            imageName="daycare/egf-daycare-book"
            className="program-desc__img"
            alt="Daycare Book"
          />
        </div>

        {/* Schedule & Hours */}
        <div className="sh-content">
          <h2 className="sh-content__title">Schedule & Hours</h2>
          <p className="sh-content__sub-heading">
            Monday to Friday - 7:00 AM to 7:00 PM (Year Round)
          </p>
          <p className="sh-content__sub-heading-2">
            Full Time, Part Time, After Care, and Drop-in
          </p>

          {/* Info Cards */}
          <div className="sh-cards row">
            {/* First Card */}
            <div className="sh-cards__card">
              {firstCard.map((item) => {
                const { time, activity } = item;

                return <SHCardText time={time} activity={activity} />;
              })}
            </div>
            {/* Second Card */}
            <div className="sh-cards__card between-row">
              <div>
                {secondCard.map((item) => {
                  const { time, activity } = item;

                  return <SHCardText time={time} activity={activity} />;
                })}
              </div>

              <p className="sh-cards__msg">
                Our schedule is flexible and can be changed/adapted for your
                child.
              </p>
            </div>

            {/* Second Card */}
            <div className="sh-cards__card between-row">
              <div>
                <h3 className="sh-cards__card-heading">Holidays</h3>
                <p className="sh-cards__card-sub-heading">
                  Closed for the following:
                </p>
                {holidays.map((holiday) => {
                  return <p className="sh-cards__card-holiday">{holiday}</p>;
                })}
              </div>

              <p className="sh-cards__msg">
                A notice will be sent out to parents ahead of time.
              </p>
            </div>
          </div>
        </div>

        {/* Meals & Supplies */}
        <div className="ms-content">
          <h2 className="ms-content__title">Meals & Supplies</h2>
          <p className="ms-content__text">
            Meals will be provided. However, families may need to bring
            appropriate food for infants under 12 months old.
          </p>
          <p className="ms-content__bold">Parents must supply the following:</p>
          <p className="ms-content__text">
            Clothing, Diapers, Baby wipes, Bottles, and Bibs.
          </p>
          <p className="ms-content__bold">For Under 12 Months Old:</p>
          <p className="ms-content__text">Milk (Formula) and Infant's Food</p>
        </div>
      </main>
    </div>
  );
}

export default Program;
