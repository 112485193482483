import React from "react";

function TextArea(props) {
  const { formik, name, label, placeholder } = props;

  return (
    <div
      className={`contact-form__textarea ${
        formik.errors[name] && formik.touched[name] ? "contact-form__error" : ""
      }`}
    >
      <label htmlFor={name} className="contact-form__label">
        {label}
      </label>
      <textarea
        name={name}
        type="Text"
        onChange={formik.handleChange}
        value={formik.values[name]}
        placeholder={placeholder}
        maxLength={1000}
      />
    </div>
  );
}

export default TextArea;
