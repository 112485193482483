import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// Components
import CDNImage from "../CDNImage/CDNImage";

function MobileNavbar() {
  const [showNav, setShowNav] = useState(false);

  const links = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "About Us",
      path: "/about",
    },
    {
      label: "Our Program",
      path: "/program",
    },
    {
      label: "Contact Us",
      path: "/contact",
    },
    {
      label: "Sign In",
      path: "/signin",
    },
  ];

  return (
    <>
      <div className="mobile-navbar between-row">
        {/* Logo */}
        <div className="logo row">
          <CDNImage
            imageName="icons/egf-logo"
            className="logo__img"
            alt="Elaine's Daycare"
          />
          <p className="logo__title">Elaine's Daycare</p>
        </div>
        {/* Nav Icon */}
        <button
          type="button"
          className="mobile-nav-menu__close"
          aria-label="Close Nav Menu"
          onClick={() => setShowNav(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="29"
            viewBox="0 0 50 29"
            fill="none"
          >
            <path
              d="M50 3.625H0V0.5H50V3.625ZM50 28.625H0V25.5H50V28.625ZM50 16.1006H0V13H50V16.1006Z"
              fill="black"
            />
          </svg>
        </button>
      </div>

      {/* Main Navbar */}
      {showNav && (
        <div className="mobile-nav-shadow">
          <div className="mobile-nav-menu">
            {/* Logo */}
            <div className="mobile-nav-menu__header between-row">
              <div className="logo row">
                <CDNImage
                  imageName="icons/egf-logo"
                  className="logo__img"
                  alt="Elaine's Daycare"
                />
                <p className="logo__title">Elaine's Daycare</p>
              </div>

              {/* Close */}
              <button
                type="button"
                className="mobile-nav-menu__close"
                aria-label="Close Nav Menu"
                onClick={() => setShowNav(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <path
                    d="M1.53214 1.53117C2.11817 0.945321 2.91288 0.616211 3.74152 0.616211C4.57016 0.616211 5.36487 0.945321 5.9509 1.53117L17.0009 12.5812L28.0509 1.53117C28.6403 0.961924 29.4297 0.646942 30.249 0.654062C31.0684 0.661182 31.8522 0.989835 32.4316 1.56924C33.011 2.14864 33.3396 2.93243 33.3468 3.75179C33.3539 4.57115 33.0389 5.36054 32.4696 5.94992L21.4196 16.9999L32.4696 28.0499C33.0389 28.6393 33.3539 29.4287 33.3468 30.248C33.3396 31.0674 33.011 31.8512 32.4316 32.4306C31.8522 33.01 31.0684 33.3387 30.249 33.3458C29.4297 33.3529 28.6403 33.0379 28.0509 32.4687L17.0009 21.4187L5.9509 32.4687C5.36151 33.0379 4.57213 33.3529 3.75277 33.3458C2.9334 33.3387 2.14961 33.01 1.57021 32.4306C0.990812 31.8512 0.662159 31.0674 0.655039 30.248C0.647919 29.4287 0.962901 28.6393 1.53214 28.0499L12.5821 16.9999L1.53214 5.94992C0.946298 5.3639 0.617188 4.56918 0.617188 3.74054C0.617188 2.91191 0.946298 2.11719 1.53214 1.53117Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>

            {/* Links */}
            <div className="mobile-nav-menu__links">
              {links.map((link) => {
                const { path, label } = link;

                return (
                  <NavLink
                    to={path}
                    className="mobile-nav-menu__link"
                    onClick={() => setShowNav(false)}
                  >
                    {label}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileNavbar;
