import React from "react";

function RightArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={"right-arrow " + props.className}
    >
      <path
        d="M8.293 2.29303C8.48053 2.10556 8.73484 2.00024 9 2.00024C9.26516 2.00024 9.51947 2.10556 9.707 2.29303L14.207 6.79303C14.3945 6.98056 14.4998 7.23487 14.4998 7.50003C14.4998 7.76519 14.3945 8.0195 14.207 8.20703L9.707 12.707C9.5184 12.8892 9.2658 12.99 9.0036 12.9877C8.7414 12.9854 8.49059 12.8803 8.30518 12.6948C8.11977 12.5094 8.0146 12.2586 8.01233 11.9964C8.01005 11.7342 8.11084 11.4816 8.293 11.293L11 8.50003H1.5C1.23478 8.50003 0.98043 8.39467 0.792893 8.20714C0.605357 8.0196 0.5 7.76525 0.5 7.50003C0.5 7.23481 0.605357 6.98046 0.792893 6.79292C0.98043 6.60539 1.23478 6.50003 1.5 6.50003H11L8.293 3.70703C8.10553 3.5195 8.00021 3.26519 8.00021 3.00003C8.00021 2.73487 8.10553 2.48056 8.293 2.29303Z"
        fill="black"
      />
    </svg>
  );
}

export default RightArrow;
