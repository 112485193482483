import React from "react";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";

// Components
import RightArrow from "../../standalone/svg/RightArrow";
import CDNImage from "../../standalone/CDNImage/CDNImage";

function AboutInfo() {
  const cards = [
    {
      icon: "female-teacher",
      title: "Professional Teaching Experience",
      info: "With professional teaching experience, our daycare provider has helped improve the developmental skills of many young kids in school. Now, she's committed to providing a more individualized learning experience for your child.",
    },
    {
      icon: "health",
      title: "Health and Safety",
      info: "At our daycare, your child's health and safety is our top priority. All of our staff, our CPR-certified, and first aid will always be available if needed. We want to ensure that you as a parent won't ever have to worry and that your child can learn and grow in a safe environment.",
    },
    {
      icon: "language",
      title: "Language Immersion",
      info: "In today's world, the ability to speak multiple languages is vital and the best time to learn a language is when you're young. This is why we provide a special language immersion program for Cantonese, Mandarin, and Vietnamese.",
    },
    {
      icon: "shapes",
      title: "Play-Based Learning",
      info: "The important thing for a child is to have fun! We want your child to naturally learn development skills through fun and engaging activities that will support them in their bright future.",
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div className="about-info row">
      <div className="about-desc">
        <h3 className="about-desc__title">About Our Daycare</h3>
        <p className="about-desc__text">
          Our family daycare is located in San Francisco, California. It's a
          small and up-and-coming daycare that is committed to providing a fun
          and safe environment for young children to prosper. Our daycare is a
          place where parents can feel comfortable leaving their children and
          help ease the stress that comes with parenthood. The founder of the
          daycare, Elaine Giang, is a mother of four and understands the beauty
          and complexity of raising children. With that empathy, she created
          this daycare to support other parents and their children.
        </p>
        <NavLink to="/program" className="about-desc__link row">
          More About Our Program
          <RightArrow />
        </NavLink>

        {/* Icon */}
        <CDNImage
          imageName="icons/3d-puzzle"
          className="about-desc__puzzle"
          alt="3D Puzzle Icon"
        />
      </div>

      {/* Cards */}
      <div
        className={`about-cards ${inView ? "about-cards-animation" : ""}`}
        ref={ref}
      >
        {cards.map((card, i) => {
          const { icon, title, info } = card;

          return (
            <div key={title} id={icon} className="about-cards__card">
              <div className="about-cards__icon center">
                <CDNImage imageName={`icons/${icon}`} alt={title} />
              </div>
              <h3 className="about-cards__title">{title}</h3>
              <p className="about-cards__info">{info}</p>
            </div>
          );
        })}
      </div>

      {/* Icons */}
      <CDNImage
        imageName="icons/3d-star"
        className="about-info__star"
        alt="3D Star Icon"
      />
    </div>
  );
}

export default AboutInfo;
