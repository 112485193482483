import React from "react";
import { NavLink } from "react-router-dom";

function AboutProvider() {
  return (
    <div className="about-provider center-vertical">
      <div className="center-vertical">
        <h1 className="about-provider__title">About the Daycare Provider</h1>
        <svg
          width="627"
          height="14"
          viewBox="0 0 627 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="about-provider__underline"
        >
          <path
            d="M1 7C21.8333 -1 42.6667 -1 63.5 7C84.3333 15 105.167 15 126 7C146.833 -1 167.667 -1 188.5 7C209.333 15 230.167 15 251 7C271.833 -1 292.667 -1 313.5 7C334.333 15 355.167 15 376 7C396.833 -1 417.667 -1 438.5 7C459.333 15 480.167 15 501 7C521.833 -1 542.667 -1 563.5 7C584.333 15 605.167 15 626 7"
            stroke="#FFB42C"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div className="provider-content row">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/elaine-giang-half.webp"}
          className="provider-content__img"
          alt="Elaine Giang"
        />
        <div className="provider-info">
          <p className="provider-info__text">
            <strong>Hello, I'm Elaine Giang</strong>, the founder of Elaine
            Giang Family Daycare. I have professional experience teaching in a
            school setting and have gained valuable experience working with
            young children. On top of this, I've had an amazing and stressful
            time raising four kids of my own, so I understand the amount of work
            and late nights that come with raising one child, let alone four.
            This is why at our daycare, we're dedicated to providing a
            nurturing, family-oriented daycare experience. Dedicated to working
            side-by-side with you and your child to ensure your child's needs
            are met and your expectations are blown away. And dedicated to
            assisting your child to a bright future.
          </p>

          <NavLink to="/about" className="provider-info__link">
            More About Us
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default AboutProvider;
