import React from "react";
import ReactPlayer from "react-player/lazy";

// Components
import TopBackground from "../../standalone/TopBackground/TopBackground";
import PlayIcon from "../../standalone/svg/PlayIcon";
import CDNImage from "../../standalone/CDNImage/CDNImage";

function About() {
  return (
    <div className="about-page">
      {/* About Background */}
      <TopBackground title="About Our Daycare" />
      {/* About Daycare Provider */}
      <div className="about-dc-provider row">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/elaine-giang.webp"}
          className="about-dc-provider__img"
          alt="Elaine Giang"
        />
        <div>
          <h3 className="about-dc-provider__title">Meet Elaine Giang</h3>
          <p className="about-dc-provider__text">
            Hello, I'm Elaine Giang, the founder of Elaine Giang Family Daycare.
            I have professional experience teaching in a school setting and have
            gained valuable experience working with young children. On top of
            this, I've had an amazing and stressful time raising four kids of my
            own, so I understand the amount of work and late nights that come
            with raising one child, let alone four. This is why at our daycare,
            we're dedicated to providing a nurturing, family-oriented daycare
            experience. Dedicated to working side-by-side with you and your
            child to ensure your child's needs are met and your expectations are
            blown away. And dedicated to assisting your child to a bright
            future.
          </p>
        </div>
      </div>
      {/* About Daycare */}
      <div className="about-daycare row">
        <CDNImage
          imageName="inside/egf-daycare-high-view"
          className="about-daycare__img"
          alt="Daycare High View"
        />

        <div className="about-daycare__bg">
          <h3 className="about-daycare__title">About Elaine's Daycare</h3>
          <p className="about-daycare__text">
            Our family daycare is located in San Francisco, California. It's a
            small and up-and-coming daycare that is committed to providing a fun
            and safe environment for young children to prosper. Our daycare is a
            place where parents can feel comfortable leaving their children and
            help ease the stress that comes with parenthood. The founder of the
            daycare, Elaine Giang, is a mother of four and understands the
            beauty and complexity of raising children. With that empathy, she
            created this daycare to support other parents and their children.
          </p>
          <p className="about-daycare__text">
            She works diligently to provide a nurturing, family-oriented daycare
            experience. This includes preparing and serving daily homemade meals
            and snacks, keeping the area clean and disinfected, assisting with
            diaper changing and potty training, and organizing fun developmental
            activities. At our daycare, we believe in play-based learning and
            encourage communal and emotional development. With separation
            anxiety being a common issue with young children, even up to
            preschool ages, we prioritize assisting children through this
            anxiety, so they'll be prepared as they continue to grow and move
            forward into their bright future.
          </p>
          <p className="about-daycare__text">
            We know this will be a long and stressful journey for you and your
            child. This is why we want to assist you with this amazing adventure
            and provide support every step of the way. We are dedicated to
            working side-by-side with you to make sure your child grows up and
            has a bright future.
          </p>
          <p className="about-daycare__text">
            Ready to embark on this journey together? Contact us through this
            website, by email, or by phone to schedule a visit and discover how
            we can provide a home away from home for your child.
          </p>
        </div>
      </div>
      {/* Video */}
      <div className="about-video">
        <h1 className="about-video__title">Take a Look Inside!</h1>
      </div>
      <div className="about-video__box center">
        <ReactPlayer
          url={process.env.PUBLIC_URL + "/assets/videos/egf-daycare-vid.mp4"}
          light={
            process.env.PUBLIC_URL +
            "/assets/videos/eg-daycare-vid-thumbnail.webp"
          }
          controls={true}
          playIcon={<PlayIcon />}
        />
      </div>
    </div>
  );
}

export default About;
