import React, { createContext, useContext } from "react";

// Device Context
const DeviceContext = createContext();
export const useDevice = () => useContext(DeviceContext);
export const DeviceContextProvider = (props) => {
  // Media Queries
  const phoneMQ = window.matchMedia("(max-width: 435px)");
  const ipadMQ = window.matchMedia("(max-width: 641px)");
  const laptopMQ = window.matchMedia("(max-width: 961px)");
  const desktopMQ = window.matchMedia("(min-width: 1025px)");

  const isPhone = phoneMQ.matches;
  const isIpad = ipadMQ.matches;
  const isLaptop = laptopMQ.matches;
  const isDesktop = desktopMQ.matches;

  return (
    <DeviceContext.Provider value={{ isPhone, isIpad, isLaptop, isDesktop }}>
      {props.children}
    </DeviceContext.Provider>
  );
};
