import React from "react";
import { NavLink } from "react-router-dom";

// Components
import CDNImage from "../../standalone/CDNImage/CDNImage";

function Hero() {
  return (
    <>
      {/* Rectangles */}
      <div className="yellow-rect"></div>
      <div className="green-rect"></div>
      {/* Main */}
      <main className="hero between-row">
        <div className="hero-content">
          <h2 className="hero-content__title">
            Where Little Minds Shine Bright !
          </h2>
          <p className="hero-content__text">
            Our home-based daycare is located in San Francisco, California, and
            is mainly for infants from three months old to preschool age. Our
            mission is to create a fun and stress-free environment for the
            children and the parents. At the daycare, we provide food, fun
            activities, sleeping areas, and many other things to provide an
            enjoyable experience for your child.
          </p>

          <div className="row">
            <NavLink to="/contact" className="contact-link">
              Contact Us
            </NavLink>
            <NavLink to="/signup" className="signup-link">
              Sign Up
            </NavLink>
          </div>
        </div>

        {/* Image */}
        <CDNImage
          imageName="daycare/egf-daycare-two"
          className="hero__img"
          alt="Daycare Kids"
        />
      </main>
    </>
  );
}

export default Hero;
