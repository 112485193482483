import React from "react";

function RedWave(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1440"
      height="167"
      viewBox="0 0 1440 167"
      fill="none"
      className={"red-wave " + props.className}
    >
      <path
        d="M0 8.59993L40 20.3149C80 32.2499 160 55.3499 240 55.5149C320 55.3499 400 32.2499 480 17.3999C560 2.54993 640 -2.95007 720 2.71493C800 8.59993 880 26.1999 960 46.7149C1040 67.4499 1120 90.5499 1200 84.8849C1280 78.9999 1360 43.7999 1400 26.1999L1440 8.59993V167H1400C1360 167 1280 167 1200 167C1120 167 1040 167 960 167C880 167 800 167 720 167C640 167 560 167 480 167C400 167 320 167 240 167C160 167 80 167 40 167H0V8.59993Z"
        fill="#FF758B"
      />
    </svg>
  );
}

export default RedWave;
