import React from "react";

function ShortDownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className="short-down-arrow"
    >
      <path d="M7.5 12L0 4H15L7.5 12Z" fill="black" />
    </svg>
  );
}

export default ShortDownArrow;
