import React from "react";
import { NavLink } from "react-router-dom";

// Components
import TopBackground from "../../standalone/TopBackground/TopBackground";

function NotFound() {
  return (
    <div className="not-found-page center-vertical">
      <TopBackground
        title={
          <>
            <div className="not-found__box center-vertical">
              <h1 className="not-found__title">404</h1>
              <p className="not-found__text">
                The page you are looking for can't be found.
              </p>
            </div>
          </>
        }
      />

      {/* Back Home */}
      <NavLink className="not-found__home" to="/">
        Back Home
      </NavLink>
    </div>
  );
}

export default NotFound;
