import React from "react";

function Mail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
    >
      <path
        d="M3 20C2.3125 20 1.72417 19.7554 1.235 19.2663C0.745833 18.7771 0.500833 18.1883 0.5 17.5V2.5C0.5 1.8125 0.745 1.22417 1.235 0.735C1.725 0.245833 2.31333 0.000833333 3 0H23C23.6875 0 24.2763 0.245 24.7663 0.735C25.2563 1.225 25.5008 1.81333 25.5 2.5V17.5C25.5 18.1875 25.2554 18.7763 24.7663 19.2663C24.2771 19.7563 23.6883 20.0008 23 20H3ZM13 11.25L3 5V17.5H23V5L13 11.25ZM13 8.75L23 2.5H3L13 8.75ZM3 5V2.5V17.5V5Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default Mail;
