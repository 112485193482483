import React from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

// Components
import RedWave from "../../standalone/svg/RedWave";
import RippleButton from "../../standalone/buttons/RippleButton";
import PlayIcon from "../../standalone/svg/PlayIcon";

function ScheduleVisit() {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div className="schedule-visit">
      <RedWave />
      <div ref={ref} className="sv-content">
        <h1 className="sv-content__title">
          Want to See More? Schedule a Visit!
        </h1>
        <div className="between-row">
          {/* Video Box */}
          <div
            className={`video-box center ${
              inView ? "video-box-animation" : ""
            }`}
          >
            <ReactPlayer
              url={
                process.env.PUBLIC_URL + "/assets/videos/egf-daycare-vid.mp4"
              }
              light={
                process.env.PUBLIC_URL +
                "/assets/videos/eg-daycare-vid-thumbnail.webp"
              }
              controls={true}
              playIcon={<PlayIcon />}
            />
          </div>
          {/* Contact Box */}
          <div
            className={`contact-box center-vertical ${
              inView ? "contact-box-animation" : ""
            }`}
          >
            <h1 className="contact-box__title">Contact Us for a Visit</h1>
            <p className="contact-box__info">
              <strong>Email:</strong> Elainegiang16@gmail.com
            </p>
            <p className="contact-box__info">
              <strong>Phone:</strong> (415) 786-4669
            </p>
            <p className="contact-box__or">OR</p>
            <p className="contact-box__text">
              Contact us directly through this website by clicking the button
              below
            </p>
            <RippleButton
              text="Contact Us"
              className="contact-box__link"
              color="blue"
              onClick={() => navigate("/contact")}
            />
          </div>
        </div>
      </div>
      <RedWave className="reverse" />
    </div>
  );
}

export default ScheduleVisit;
