import React from "react";

// Components
import Hero from "./Hero";
import InsideImages from "./InsideImages";
import AboutInfo from "./AboutInfo";
import ScheduleVisit from "./ScheduleVisit";
import AboutProvider from "./AboutProvider";

function Home() {
  return (
    <div className="home-page">
      <Hero />
      <InsideImages />
      <AboutInfo />
      <ScheduleVisit />
      <AboutProvider />
    </div>
  );
}

export default Home;
