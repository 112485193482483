import React from "react";

// Components
import CDNImage from "../../standalone/CDNImage/CDNImage";

function InsideImages() {
  const images = [
    {
      src: "high-view",
      alt: "High View",
    },
    {
      src: "corner",
      alt: "Corner View",
    },
    {
      src: "chairs",
      alt: "Chairs",
    },
    {
      src: "toys",
      alt: "Toys",
    },
    {
      src: "posters",
      alt: "Posters",
    },
    {
      src: "side-corner",
      alt: "Side Corner",
    },
    {
      src: "cribs",
      alt: "Cribs",
    },
  ];

  return (
    <div className="inside-images">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1440"
        height="248"
        viewBox="0 0 1440 248"
        fill="none"
        className="bg-svg"
      >
        <path
          d="M0 74.4L40 66.1075C80 58.125 160 41.075 240 41.3075C320 41.075 400 58.125 480 86.8C560 115.475 640 157.325 720 161.2C800 165.075 880 132.525 960 124C1040 115.475 1120 132.525 1200 115.707C1280 99.2 1360 49.6 1400 24.8L1440 0V248H1400C1360 248 1280 248 1200 248C1120 248 1040 248 960 248C880 248 800 248 720 248C640 248 560 248 480 248C400 248 320 248 240 248C160 248 80 248 40 248H0V74.4Z"
          fill="#758BFF"
        />
      </svg>

      {/* Content */}
      <div className="inside-images-content">
        <h2 className="inside-images-content__title">Take a Look Inside!</h2>

        {/* Code from OLD VERSION */}
        <div className="carousel">
          <div className="carousel__wrapper">
            {images.map((img, i) => {
              return (
                <div key={img.alt} className="item" id={"slide-" + i}>
                  <CDNImage
                    imageName={`inside/egf-daycare-${img.src}`}
                    className="carousel__img"
                    alt={img.alt}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsideImages;
