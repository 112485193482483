import React from "react";

// Components
import CDNImage from "../CDNImage/CDNImage";

function TopBackground(props) {
  return (
    <div className="top-bg">
      <CDNImage
        imageName="inside/egf-daycare-corner"
        className="top-bg__img"
        alt="Daycare Corner View"
      />
      <h1 className="top-bg__title">{props.title}</h1>
    </div>
  );
}

export default TopBackground;
