import React from "react";

function RippleButton(props) {
  const { text, className, color, onClick } = props;

  return (
    <button
      type="button"
      className={"btn btn--" + color + " " + className}
      onClick={onClick}
      aria-label={text}
    >
      <span className="btn__txt">{text}</span>
      <i className="btn__bg" aria-hidden="true"></i>
      <i className="btn__bg" aria-hidden="true"></i>
      <i className="btn__bg" aria-hidden="true"></i>
      <i className="btn__bg" aria-hidden="true"></i>
    </button>
  );
}

export default RippleButton;
