import React from "react";

function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      className="close"
    >
      <path
        d="M157.66 146.341C158.403 147.084 158.992 147.966 159.395 148.937C159.797 149.909 160.004 150.949 160.004 152.001C160.004 153.052 159.797 154.093 159.395 155.064C158.992 156.035 158.403 156.917 157.66 157.661C156.916 158.404 156.034 158.993 155.063 159.396C154.092 159.798 153.051 160.005 152 160.005C150.948 160.005 149.908 159.798 148.936 159.396C147.965 158.993 147.083 158.404 146.34 157.661L79.9996 91.3105L13.6596 157.661C12.1584 159.162 10.1225 160.005 7.99957 160.005C5.87665 160.005 3.84069 159.162 2.33957 157.661C0.83844 156.159 -0.00488277 154.123 -0.00488281 152.001C-0.00488285 149.878 0.83844 147.842 2.33957 146.341L68.6896 80.0005L2.33957 13.6605C0.83844 12.1594 -0.00488283 10.1235 -0.00488281 8.00054C-0.0048828 5.87763 0.83844 3.84167 2.33957 2.34054C3.84069 0.839417 5.87665 -0.00390623 7.99957 -0.00390625C10.1225 -0.00390627 12.1584 0.839417 13.6596 2.34054L79.9996 68.6905L146.34 2.34054C147.841 0.839417 149.877 -0.00390629 152 -0.00390625C154.122 -0.00390621 156.158 0.839417 157.66 2.34054C159.161 3.84167 160.004 5.87763 160.004 8.00054C160.004 10.1235 159.161 12.1594 157.66 13.6605L91.3096 80.0005L157.66 146.341Z"
        fill="black"
      />
    </svg>
  );
}

export default Close;
