import React, { createContext, useContext } from "react";
import { Cloudinary } from "@cloudinary/url-gen";

// Cloudinary Context
const CDNContext = createContext();
export const useCDN = () => useContext(CDNContext);
export const CDNContextProvider = (props) => {
  const { REACT_APP_CLOUDINARY_NAME } = process.env;
  const cld = new Cloudinary({
    cloud: { cloudName: REACT_APP_CLOUDINARY_NAME },
  });

  return (
    <CDNContext.Provider value={{ cld }}>{props.children}</CDNContext.Provider>
  );
};
