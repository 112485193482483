import React from "react";

// Contexts
import { CDNContextProvider } from "./CDN.context";
import { DeviceContextProvider } from "./Device.context";

function ContextProvider(props) {
  return (
    <DeviceContextProvider>
      <CDNContextProvider>{props.children}</CDNContextProvider>
    </DeviceContextProvider>
  );
}

export default ContextProvider;
